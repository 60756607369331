export const DEV_BASE_URL = process.env.NEXT_PUBLIC_DEV_BASE_URL;
export const STAGE_BASE_URL = process.env.NEXT_PUBLIC_STAGE_BASE_URL;
export const PRODUCTION_BASE_URL = process.env.NEXT_PUBLIC_PRODUCTION_BASE_URL;
export const IS_DEV_MODE = process.env.NODE_ENV === "development";
export const IS_PROD_MODE = process.env.NODE_ENV === "production";
export const IS_PROD_ENV = process.env.NEXT_PUBLIC_STAGE === "production";
export const IS_DEV_ENV = process.env.NEXT_PUBLIC_STAGE === "development";
export const FLOMNI_API_KEY = process.env.NEXT_PUBLIC_FLOMNI_API_KEY;
export const FLOMNI_API_URL = process.env.NEXT_PUBLIC_FLOMNI_API;
export const FLOMNI_CHAT_OPEN_DELAY =
  process.env.NEXT_PUBLIC_FLOMNI_CHAT_OPEN_DELAY;
export const LOAD_ANALYTICS = process.env.NEXT_PUBLIC_LOAD_ANALYTICS;
export const DEEPLINK_URL = process.env.NEXT_PUBLIC_DEEPLINK_URL;
export const VERIDOC_SCRIPT = process.env.NEXT_PUBLIC_VERIDOC_SCRIPT;
export const POPUP_DEEPLINK_URL = process.env.NEXT_PUBLIC_POPUP_DEEPLINK_URL;
export const YANDEX_MAPS_API_KEY = process.env.NEXT_PUBLIC_YANDEX_MAPS_API_KEY;
export const MINDBOX_API_TRACKER_URL =
  process.env.NEXT_PUBLIC_MINDBOX_API_TRACKER_URL;
export const WHATSAPP_SEND_API = process.env.NEXT_PUBLIC_WHATSAPP_SEND_API;
export const TELEGRAM_SHARE_API = process.env.NEXT_PUBLIC_TELEGRAM_SHARE_API;
export const INSTAGRAM_SHARE_API = process.env.NEXT_PUBLIC_INSTAGRAM_SHARE_API;
export const FACEBOOK_SHARE_API = process.env.NEXT_PUBLIC_FACEBOOK_SHARE_API;
export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME;
export const YANDEX_VERIFICATION_CODE =
  process.env.NEXT_PUBLIC_YANDEX_VERIFICATION_CODE;
export const TOP_HOTELS_API_KEY = process.env.NEXT_PUBLIC_TOP_HOTELS_API_KEY;
export const TOP_HOTELS_API_URL = process.env.NEXT_PUBLIC_TOP_HOTELS_API_URL;
export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
