"use client";

import * as amplitude from "@amplitude/analytics-browser";

import { IS_PROD_ENV, AMPLITUDE_API_KEY } from "../env";

import { TokenService } from "@/shared/model/api/token";

export const AmplitudeInit = () => {
  if (typeof window !== "undefined" && IS_PROD_ENV) {
    amplitude.init(AMPLITUDE_API_KEY as string, TokenService.getUser()?.id);
  }
  return null;
};
