"use client";
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Script from "next/script";

import { MINDBOX_API_TRACKER_URL } from "../env";

import { isClient } from "@/shared/lib/helpers/isClient";

const generateMindBoxSettings = () => {
  if (!isClient || !MINDBOX_API_TRACKER_URL) {
    return null;
  }

  const mindbox =
    window.mindbox ||
    function () {
      mindbox.queue.push(arguments);
    };
  mindbox.queue = mindbox.queue || [];
  mindbox("create");

  return null;
};

export const MindboxScripts = () => {
  return (
    <>
      <Script id="mindbox" strategy="beforeInteractive">
        {generateMindBoxSettings()}
      </Script>
      <Script
        async
        strategy="beforeInteractive"
        src={MINDBOX_API_TRACKER_URL}
      />
    </>
  );
};
