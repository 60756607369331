"use client";
import { ReactNode } from "react";

import { AppRouterCacheProvider } from "@mui/material-nextjs/v14-appRouter";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { mainTheme, getSsrMatchMedia } from "./mainTheme";

export default function ThemeRegistry({
  children,
  deviceType,
}: {
  deviceType?: string;
  children: ReactNode;
}) {
  return (
    <AppRouterCacheProvider options={{ key: "mui", prepend: true }}>
      <ThemeProvider
        theme={createTheme(mainTheme, {
          components: {
            // Change the default options of useMediaQuery
            MuiUseMediaQuery: {
              defaultProps: {
                ssrMatchMedia: getSsrMatchMedia(deviceType || "desktop"),
              },
            },
          },
        })}
      >
        <CssBaseline />
        {children}
      </ThemeProvider>
    </AppRouterCacheProvider>
  );
}
