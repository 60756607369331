import Cookies from "js-cookie";

import { TCommonUserData } from "../../types/auth";

const getCookiesAccessToken = () => {
  return Cookies.get("token");
};

const setCookiesAccessToken = (token: string) => {
  return Cookies.set("token", token, { expires: 7 });
};

const getUser = () => {
  return JSON.parse(Cookies.get("user") ?? "null") as TCommonUserData;
};

const setUser = (user: TCommonUserData) => {
  Cookies.set("user", JSON.stringify(user), { expires: 30 });
};

const removeUser = () => {
  Cookies.remove("user");
};

const removeToken = () => {
  Cookies.remove("token");
};

export const TokenService = {
  setUser,
  getUser,
  removeUser,
  removeToken,
  getCookiesAccessToken,
  setCookiesAccessToken,
};
