"use client";
import React from "react";

import {
  Info,
  Cancel,
  ErrorOutline,
  CheckCircleOutline,
} from "@mui/icons-material";
import { createTheme, ThemeOptions } from "@mui/material/styles";
import mediaQuery from "css-mediaquery";

import { TCustomTheme, TPaletteColors } from "@/shared/ui/styles/theme/types";

const palette: TPaletteColors = {
  mode: "light",
  cloud: "#eaedf2",
  system: {
    blue: "#4188D9",
  },
  background: {
    default: "#f2f5f8",
  },
  info: {
    main: "#1B579B",
    light: "#CFE1F5",
  },

  success: {
    high: "#7CCC4B",
    main: "#0EAB69",
    light: "#D6FDEC",
  },
  error: {
    main: "#ff2c2c",
    light: "#fee4e2",
    secondary: "#ED5D5D",
  },
  warning: {
    main: "#E89313",
    light: "#FCF4D5",
    secondary: "#E89313",
  },
  dominant: {
    dop: "#F2F5F7",
    main: "#F9FAFC",
    cloud: "#EAEEF2",
    white: "#FFFFFF",
    background: "#F8F8F8",
  },
  primary: {
    dark: "#1b589b",
    main: "#2b6bb2",
    light: "#6090c5",
    whiteblue: "#e6ecf2",
    extralight: "#95b5d9",
  },
  secondary: {
    text: "#fff",
    dark: "#e6ecf2",
    main: "#e6ecf2",
    darkGlass: "#272E36B2",
    contrastText: "#2b6bb2",
  },
  content: {
    loan: "#39362A",
    primary: "#04172C",
    secondary: "#435161",
    "grey-100": "#E6E8EA",
    "grey-200": "#C0C5CA",
    "grey-400": "#818A95",
  },
  grey: {
    text: "#435061",
    dark: "#04172C",
    main: "#435061",
    white: "#e6e8ea",
    light: "#818a95",
    default: "#c2c9d1",
    content: "#435161",
    extralight: "#c0c5ca",
    bgWhiteBlue: "#F0F2F5",
  },
  accent: {
    yellow: {
      low: "#FCF4D5",
      loan: "#FFDD65",
      high: "#D6C467",
    },
    orange: {
      stars: "#FAB042",
      primary: "#F17C3B",
      attention: "#E89313",
    },
    red: {
      light: "#FAE7E7",
      error: "#ED5D5D",
      "error-dark": "#FF2C2C",
      "lightest-hover": "#F9F0F0",
    },
    green: {
      tagbg: "#EBF9F3",
      light: "#D6FDEC",
      rating: "#A2D1BE",
      success: "#0EAB69",
      secondary: "#6BC534",
    },
    brand: {
      dark: "#1B579B",
      light: "#CFE1F5",
      primary: "#2B6BB2",
      lightest: "#E6ECF2",
      secondary: "#608FC5",
    },
  },
};

export const mainTheme = createTheme({
  palette,

  breakpoints: {
    values: {
      xs: 0,
      md: 960,
      sm: 600,
      xl: 1920,
      ml: 1325,
      lg: 1120,
      smd: 750,

      searchMobile: 850,
      searchDesktop: 1180,
    },
  },
  scrollbars: {
    default: {
      "&::-webkit-scrollbar": {
        width: "12px",
        height: "12px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#818a95",
        backgroundClip: "padding-box",
      },
      "&::-webkit-scrollbar-thumb": {
        border: "4px solid",
        borderRadius: "9999px",
        backgroundColor: "#c0c5ca",
        backgroundClip: "padding-box",
        borderColor: "rgba(0, 0, 0, 0)",
      },
    },
  },
  typography: {
    h4: undefined,
    h5: undefined,
    h6: undefined,
    body1: undefined,
    body2: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    lead: {
      fontSize: 22,
      fontWeight: 400,
    },
    body: {
      fontSize: 18,
      fontWeight: 400,
    },
    small: {
      fontSize: 16,
      fontWeight: 400,
    },
    label: {
      fontSize: 14,
      fontWeight: 400,
    },
    subtitle: {
      fontSize: 26,
      fontWeight: 400,
    },
    h1: {
      fontSize: 64,
      lineHeight: 1,
      fontWeight: 400,
      letterSpacing: "normal",
    },
    h2: {
      fontSize: 48,
      fontWeight: 400,
      lineHeight: 1.15,
      letterSpacing: "normal",
    },
    h3: {
      fontSize: 32,
      fontWeight: 400,
      lineHeight: 1.15,
      letterSpacing: "normal",
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.15,
      letterSpacing: "0.2px",
    },

    fontFamily: [
      "GT Eesti Pro Display",
      "ui-sans-serif",
      "sans-serif",
      "system-ui",
    ].join(","),
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: "20px 24px",

          ".MuiAccordionSummary-content": {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: "0 20px 20px",
          borderTop: "1px solid",
          borderColor: palette.content["grey-100"],
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        square: true,
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          padding: 0,
          boxShadow: "none",
          border: "1px solid",
          borderRadius: "8px",
          borderColor: palette.dominant.cloud,

          "&:before": {
            display: "none",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          height: "32px",
        },
        sizeMedium: {
          height: "42px",
        },
        labelSmall: {
          fontSize: "12px",
          padding: "0 14px",
          lineHeight: "16px",
        },
        labelMedium: {
          fontSize: "14px",
          padding: "0 16px",
          lineHeight: "16px",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {},
        rail: {
          opacity: 1,
          color: palette.grey.white,
          borderColor: palette.grey.white,
        },
        thumb: {
          width: "24px",
          height: "24px",
          border: "1px solid",
          borderRadius: "8px",
          backgroundColor: "#fff",
          boxShadow: "none !important",
          borderColor: palette.grey.white,

          "&::before": {
            boxShadow: "none",
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          height: "48px",
        },

        sizeSmall: {
          height: "36px",
          padding: "12px",
        },

        text: {
          transition: "0.2s",
          color: palette.accent.brand.primary,

          "&:hover": {
            opacity: "0.7",
            backgroundColor: "transparent",
          },
        },

        root: {
          height: "42px",
          fontSize: "16px",
          boxShadow: "none",
          fontWeight: "400",
          lineHeight: "19px",
          borderRadius: "8px",
          padding: "12px 16px",
          textTransform: "none",
        },

        contained: {
          "&:hover": {
            boxShadow: "none",
            backgroundColor: palette.accent.brand.secondary,
          },

          "&:disabled": {
            color: palette.grey.extralight,
            backgroundColor: palette.grey.white,
          },
        },

        outlined: {
          color: palette.accent.brand.primary,
          backgroundColor: palette.accent.brand.lightest,
          border: `1px solid ${palette.accent.brand.lightest}`,

          "&:focus": {
            border: `1px solid ${palette.accent.brand.light}`,
          },

          "&:disabled": {
            color: palette.grey.extralight,
            backgroundColor: palette.grey.white,
            border: `1px solid ${palette.grey.white}`,
          },

          "&:hover": {
            color: palette.accent.brand.dark,
            backgroundColor: palette.accent.brand.lightest,
            border: `1px solid ${palette.accent.brand.light}`,
          },
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        CloseIon: React.createElement(Cancel),
        iconMapping: {
          info: React.createElement(Info),
          error: React.createElement(Cancel),
          warning: React.createElement(ErrorOutline),
          success: React.createElement(CheckCircleOutline),
        },
      },
      styleOverrides: {
        icon: {
          padding: 0,
          color: "#fff",
          marginRight: "9px",
        },
        root: {
          borderRadius: "8px",
          padding: "12px 16px",
          alignItems: "center",
        },
        message: {
          padding: 0,
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "16.24px",
        },
        standardInfo: {
          color: palette.dominant.white,
          backgroundColor: palette.accent.brand.secondary,
          "& .MuiAlert-icon": {
            color: palette.dominant.white,
          },
        },
        standardWarning: {
          color: palette.dominant.white,
          backgroundColor: palette.accent.orange.stars,
          "& .MuiAlert-icon": {
            color: palette.dominant.white,
          },
        },
        standardSuccess: {
          color: palette.dominant.white,
          backgroundColor: palette.accent.green.success,
          "& .MuiAlert-icon": {
            color: palette.dominant.white,
          },
        },
        standardError: {
          color: palette.dominant.white,
          backgroundColor: palette.accent.red.error,
          "& .MuiAlert-icon": {
            "& svg": {
              "& path": {
                fill: palette.dominant.white,
              },
            },
          },
        },
        filled: {
          "& .MuiAlert-icon": {
            "& svg": {
              "& path": {
                fill: "#fff",
              },
            },
          },
          "& .MuiAlert-action": {
            "& svg": {
              "& path": {
                fill: "#fff",
              },
            },
          },
        },
        action: {
          padding: "2px",
          cursor: "pointer",
          borderRadius: "50%",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          "& svg": {
            width: "20px",
            height: "20px",
            "& path": {
              fill: "#fff",
            },
          },
        },
        outlinedInfo: {
          border: "1px solid",
          color: palette.accent.brand.dark,
          borderColor: palette.accent.brand.primary,
          backgroundColor: palette.accent.brand.light,
          "& .MuiAlert-icon": {
            "& svg": {
              "& path": {
                fill: palette.accent.brand.primary,
              },
            },
          },
          "& .MuiAlert-action": {
            "& svg": {
              "& path": {
                fill: palette.accent.brand.primary,
              },
            },
          },
        },
        outlinedSuccess: {
          border: "1px solid",
          color: palette.accent.green.success,
          borderColor: palette.accent.green.success,
          backgroundColor: palette.accent.green.light,
          "& .MuiAlert-icon": {
            "& svg": {
              "& path": {
                fill: palette.accent.green.success,
              },
            },
          },
          "& .MuiAlert-action": {
            "& svg": {
              "& path": {
                fill: palette.accent.green.success,
              },
            },
          },
        },
        outlinedError: {
          border: "1px solid",
          borderColor: palette.accent.red.error,
          color: palette.accent.red["error-dark"],
          backgroundColor: palette.accent.red.light,
          "& .MuiAlert-icon": {
            "& svg": {
              "& path": {
                fill: palette.accent.red["error-dark"],
              },
            },
          },
          "& .MuiAlert-action": {
            "& svg": {
              "& path": {
                fill: palette.accent.red["error-dark"],
              },
            },
          },
        },
        outlinedWarning: {
          border: "1px solid",
          color: palette.accent.orange.attention,
          backgroundColor: palette.accent.yellow.low,
          borderColor: palette.accent.orange.attention,
          "& .MuiAlert-icon": {
            "& svg": {
              "& path": {
                fill: palette.accent.orange.attention,
              },
            },
          },
          "& .MuiAlert-action": {
            "& svg": {
              "& path": {
                fill: palette.accent.orange.attention,
              },
            },
          },
        },
      },
    },
  },
} as ThemeOptions) as TCustomTheme;

export const getSsrMatchMedia = (deviceType: string) => (query: never) => ({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
  matches: mediaQuery.match(query, {
    // The estimated CSS width of the browser.
    width: deviceType === "mobile" ? "0px" : "1180px",
  }),
});
